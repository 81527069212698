<template>
  <div>
    <van-nav-bar title="会员卡信息" left-arrow @click-left="onClickLeft" />
    <!-- <div class="card">
      <div class="subscript">月卡VIP</div>
      <img src="https://t.yhaocang.com/upload/sf_static/img/myCard/30day.png" />
    </div> -->
    <img class="card" :src="img" alt="" />
    <div class="press">长按保存图片</div>
    <div class="tip">
      <p class="title">兑换提醒</p>
      <p>1.此会员卡仅在1号奢仓APP内使用</p>
      <p>
        2.会员卡激活后，立即生效，无法退还，需谨慎保存，人为因素导致会员卡被盗用激活的，本公司既不负责！
      </p>
      <p>3.更多会员权益请在1号奢仓APP内查看</p>
    </div>
    <div class="btn-box">
      <div
        style="background: #343230; color: #fff"
        v-clipboard:copy="code"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        复制兑换码
      </div>
      <div @click="onClickLeft">返回</div>
    </div>
  </div>
</template>

<script>
import { makeCard } from "./../../api/myCard";
export default {
  data() {
    return {
      img: "",
      code: ""
    };
  },
  created() {
    this.GetMakeCard();
  },
  methods: {
    GetMakeCard() {
      makeCard({ id: this.$route.query.id }).then(res => {
        console.log(res);
        this.img = process.env.VUE_APP_IMG_URL + res.data.data.card.imgPath;
        this.code = res.data.data.card.code;
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onCopy() {
      this.$toast("内容已复制到剪切板！");
    },
    // 复制失败时的回调函数
    onError() {
      this.$toast("抱歉，复制失败！");
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
.card {
  width: 692px;
  height: 385px;
  margin: auto;
  display: block;
  margin-top: 18px;
  // background: url("../../../src/assets/cardbgc.png") 0 0 no-repeat;
  // background-size: 100%;
  // .subscript {
  //   width: 215px;
  //   height: 74px;
  //   font-size: 35px;
  //   font-weight: bold;
  //   color: #1f1f1f;
  //   // background: url("https://t.yhaocang.com/upload/sf_static/img/myCard/jiaobaio.png")
  //   //   0 0 no-repeat;
  //   // background-size: 100%;
  //   text-align: center;
  //   line-height: 74px;
  //   box-sizing: border-box;
  //   padding-left: 40px;
  //   margin-left: auto;
  //   margin-right: 6px;
  // }
  // img {
  //   width: 219px;
  //   margin-left: auto;
  //   display: block;
  //   padding-top: 140px;
  //   margin-right: 20px;
  // }
}
.tip {
  padding: 36px;
  .title {
    // width: 122px;
    // height: 29px;
    font-size: 31px;
    font-weight: 600;
    color: #000000;
    line-height: 80px;
  }
  p {
    font-size: 25px;
    font-weight: 500;
    color: #626262;
    line-height: 40px;
  }
}
.btn-box {
  margin-top: 80px;
  div {
    width: 660px;
    height: 83px;
    border: 1px solid #343230;
    border-radius: 42px;
    line-height: 83px;
    text-align: center;
    font-size: 35px;
    font-weight: 500;
    color: #343230;
    margin: 22px auto;
  }
}
.press {
  text-align: center;
  color: #333333;
}
</style>
