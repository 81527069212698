import { axios } from "./../plugins/axios";
/**
 * @method 获取会员卡明细
 * @param {Object} parameter
 */
export function cardDetailsList(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "card",
      app: "card_list",
      ...parameter
    }
  });
}
/**
 * @method 获取会员卡详情
 * @param {Object} parameter
 * @param {Number|String} id 上一个接口返回的id
 */
export function makeCard(parameter) {
  return axios.request({
    method: "get",
    params: {
      module: "fans",
      action: "card",
      app: "make_card",
      ...parameter
    }
  });
}
